export default [
  {
    header: 'TOOLS',
    action: 'employee',
    resource: 'manage',
  },
  {
    title: 'Impostazioni',
    route: 'tools-emailtemplate-index',
    icon: 'SettingsIcon',
    action: 'employee',
    resource: 'manage',
  },
  {
    title: 'Email Template',
    route: 'tools-emailtemplate-index',
    icon: 'MailIcon',
    action: 'employee',
    resource: 'manage',
  },
  {
    title: 'Log',
    route: 'tools-log-index',
    icon: 'AlertTriangleIcon',
    action: 'employee',
    resource: 'manage',
  },
]
